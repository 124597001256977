<template>
  <div class="bg-white py-6 px-4 sm:p-6 shadow rounded-t">
    <form @submit.prevent="handleSubmit" class="space-y-8 mb-8">
      <div class="grid gap-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Notifications
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            We'll always let you know about important updates on the cases
          </p>
          <div class="grid gap-6 mt-6">
            <h1 class="leading-6 font-medium">Case Updates</h1>
            <div class="mt-4 sm:mt-0 sm:col-span-2">
              <div class="space-y-4">
                <div class="relative flex items-start">
                  <div class="flex items-center h-5">
                    <input
                      id="email"
                      name="email"
                      type="checkbox"
                      class="
                        focus:ring-blue-500
                        h-4
                        w-4
                        text-blue-600
                        border-gray-300
                        rounded
                      "
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="email" class="font-medium text-gray-700"
                      >Email</label
                    >
                    <p class="text-gray-500">
                      Get notified when by email when there is a update on the
                      cases you assigned
                    </p>
                  </div>
                </div>
                <div>
                  <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="sms"
                        name="sms"
                        type="checkbox"
                        class="
                          focus:ring-blue-500
                          h-4
                          w-4
                          text-blue-600
                          border-gray-300
                          rounded
                        "
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="candidates" class="font-medium text-gray-700"
                        >SMS</label
                      >
                      <p class="text-gray-500">
                        Get notified when by SMS when there is a update on the
                        cases you assigned
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="push"
                        name="push"
                        type="checkbox"
                        class="
                          focus:ring-blue-500
                          h-4
                          w-4
                          text-blue-600
                          border-gray-300
                          rounded
                        "
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="push" class="font-medium text-gray-700"
                        >Push Notifications</label
                      >
                      <p class="text-gray-500">
                        Get notified in app notifications when there is a update
                        on the cases you assigned
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div
    class="
      space-x-4
      flex
      justify-end
      bg-gray-50
      py-6
      px-4
      sm:p-6
      shadow
      rounded-b
    "
  >
    <button
      type="submit"
      class="
        inline-flex
        items-center
        px-4
        py-2
        border border-transparent
        shadow-sm
        text-sm
        font-medium
        rounded-md
        text-blue-700
        bg-blue-100
        hover:bg-blue-200
        focus:outline-none
        focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500
      "
    >
      Cancel
    </button>
    <button
      type="submit"
      class="
        inline-flex
        items-center
        px-4
        py-2
        border border-transparent
        shadow-sm
        text-sm
        font-medium
        rounded-md
        text-white
        bg-light-blue-600
        hover:bg-light-blue-700
        focus:outline-none
        focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500
      "
    >
      Save
    </button>
  </div>
</template>

